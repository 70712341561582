export const blue = "#2C65AC";

export const purple9 = "#22075e";

export const green = "#5b8c00";

export const yellowPrimary = "#fa8c16";

export const greenPrimary = "#52c41a";

export const redPrimary = "#e74c3c";

export const grayPrimary = "#65676b";

export const greenButton = "#3aaf50";
