import { action, observable, toJS } from "mobx";
import { CommitteeRequest } from "../requests/CommitteeRequest";

class CommitteeStore {
  /** Get committees list */
  @observable committeeList = [];
  @observable committeeListTotalCount = 0;
  @observable committeeListPageIndex = 0;
  @observable committeeListPageSize = 5;
  @observable committeeAssignmentList = [];
  @observable committeeAssignmentListTotalCount = 0;
  @observable committeeAssignmentListPageIndex = 0;
  @observable committeeAssignmentListPageSize = 5;

  @action getCommitteeList = (semesterId) => {
    return new Promise((resolve, reject) => {
      CommitteeRequest.getCommitteeList(
        this.committeeListPageSize,
        this.committeeListPageIndex,
        semesterId
      )
        .then((response) => {
          this.committeeListTotalCount = response.data.totalCount;
          this.committeeList = response.data.data;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  @action getCommitteeAssignmentList = (semesterId) => {
    return new Promise((resolve, reject) => {
      CommitteeRequest.getCommitteeAssignmentList(
        this.committeeAssignmentListPageSize,
        this.committeeAssignmentListPageIndex,
        semesterId
      )
        .then((response) => {
          this.committeeAssignmentListTotalCount = response.data.totalCount;
          this.committeeAssignmentList = response.data.data;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  /** Clear store */
  @action clearStore = () => {
    this.committeeList = [];
    this.committeeListPageIndex = 0;
    this.committeeListPageSize = 5;
    this.committeeListTotalCount = 0;
    this.committeeAssignmentList = [];
    this.committeeAssignmentListPageIndex = 0;
    this.committeeAssignmentListPageSize = 5;
    this.committeeAssignmentListTotalCount = 0;
  };
}

export default new CommitteeStore();
