import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// Component
// Ant design
import { Avatar, Button, Drawer, Row, Col, Typography, Steps } from "antd";
// Other
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import MemberItem from "../../pages/Registration/TeamPage/MemberItem";
import { DATE_FORMAT_SLASH, MEMBER_STATUS } from "../../constants";
const { Title } = Typography;
const { Step } = Steps;
const ViewProgress = (props) => {
  const {
    history,
    semesterStore,
    id,
    group,
    members,
    onStepChange,
    setCurrentIndex,
  } = props;
  const currentDate = moment();
  const [milestones, setMilestone] = useState([]);

  useEffect(() => {
    getMilestoneGuidance();
  }, [id]);

  const getMilestoneGuidance = async () => {
    try {
      const res = await semesterStore.getMilestoneGuidancePhase(id);

      addNewMilestone(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  const addNewMilestone = (milestones) => {
    const lastMilestone = milestones[milestones.length - 1];
    const newStartDate = new Date(lastMilestone.endDate);
    newStartDate.setDate(newStartDate.getDate());
    const newEndDate = new Date(newStartDate);
    newEndDate.setDate(newEndDate.getDate() + 7);
    const newMilestone = {
      id: milestones.length + 1,
      milestone: {
        name: "Final",
      },
      time: "1 weeks",
      duration: "1 weeks",
      startDate: newStartDate.toISOString(),
      endDate: newEndDate.toISOString(),
    };

    setMilestone([...milestones, newMilestone]);
  };

  const getCurrentStepIndex = () => {
    for (let i = 0; i < milestones.length; i++) {
      if (
        moment(currentDate).format("YYYY-MM-DD") >=
          moment(milestones[i].startDate).format("YYYY-MM-DD") &&
        moment(currentDate).format("YYYY-MM-DD") <=
          moment(milestones[i].endDate).format("YYYY-MM-DD")
      ) {
        return i;
      }
    }
    return -1;
  };

  const currentStepIndex = getCurrentStepIndex();
  useEffect(() => {
    if (onStepChange) {
      onStepChange(currentStepIndex);
    }
    if (setCurrentIndex) {
      setCurrentIndex(currentStepIndex);
    }
  }, [currentStepIndex]);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <div
      className="sticky z-40 top-0 p-4"
      style={{ backgroundColor: "#f1f5f9" }}
    >
      <div className="relative">
        <Drawer
          title="Group Information"
          placement="right"
          onClose={onClose}
          visible={visible}
          width="45%"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-10 mb-6">
              <Title level={4}>{group?.name}</Title>
              <p>
                Created at: {moment(group?.createdAt).format(DATE_FORMAT_SLASH)}
              </p>
            </div>
          </div>
          <Row>
            <Col span={12}>
              <p className="title">Abbreviations</p>
              <p className="content">{group?.abbreviations}</p>
            </Col>
            <Col span={12}>
              <p className="title">Vietnamese Title</p>
              <p className="content">{group?.vietnameseTitle}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p className="title">Profession</p>
              <p className="content">Information Technology A (K15 trở đi)</p>
            </Col>
            <Col span={12}>
              <p className="title">Specialty</p>
              <p className="content">Lập trình .NET</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="title">Description</p>
              <p className="content">{group?.description}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="title">Keywords</p>
              <p className="content">{group?.keywords}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="title">Members</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>
                Max: <span>5 members</span>
              </p>
            </Col>
          </Row>
          <div className="members">
            {members
              ?.sort((a, b) => {
                // Ưu tiên thành viên có role là "OWNER"
                if (a.role.includes("OWNER")) return -1;
                if (b.role.includes("OWNER")) return 1;

                /// Nếu không phải "OWNER", ưu tiên "ingroup" giảm dần theo updateAt
                if (
                  a.status === MEMBER_STATUS.INGROUP &&
                  b.status === MEMBER_STATUS.INGROUP
                ) {
                  return b.updateAt - a.updateAt;
                }
                if (a.status === MEMBER_STATUS.INGROUP) return -1;
                if (b.status === MEMBER_STATUS.INGROUP) return 1;

                // Cuối cùng là những thành viên "pending"
                if (
                  a.status === MEMBER_STATUS.PENDING &&
                  b.status !== MEMBER_STATUS.PENDING
                )
                  return 1;
                if (
                  b.status === MEMBER_STATUS.PENDING &&
                  a.status !== MEMBER_STATUS.PENDING
                )
                  return -1;

                // Nếu cùng status, giữ nguyên thứ tự
                return 0;
              })
              ?.map((member, index) => (
                <MemberItem key={index} member={member} group={group} />
              ))}
          </div>
        </Drawer>
      </div>
      <div className="flex">
        <Steps
          labelPlacement="vertical"
          current={currentStepIndex === -1 ? 0 : currentStepIndex}
        >
          {milestones.map((milestone, index) => (
            <Step
              key={index}
              title={milestone.milestone?.name}
              status={
                currentStepIndex === -1
                  ? "wait"
                  : index < currentStepIndex
                  ? "finish"
                  : index === currentStepIndex
                  ? "process"
                  : "wait"
              }
            />
          ))}
        </Steps>
        {group && (
          <Button type="primary" onClick={showDrawer} className=" mr-2 h-16">
            Group <br />
            <div className="mt-2">Info</div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(
  withRouter(
    inject("semesterStore", "loadingAnimationStore")(observer(ViewProgress))
  )
);
