import React, { useState, memo, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Modal, Table, Input, message, Checkbox } from "antd";
import { DATE_FORMAT_SLASH, MEMBER_STATUS } from "../../constants";
import { StatusTag } from "../../components/Common/StatusTag";

const MilestoneEvaluationProgressModal = ({
  visible,
  setVisiblePopup,
  currentMilestone,
  semesterStore,
  group,
  handleCancel,
  milestoneStatus,
}) => {
  useEffect(() => {
    const initialSelected = {};
    const initialStudentStatus = {};
    milestoneStatus?.forEach((status) => {
      initialSelected[status.milestoneId] = status.status;
      initialStudentStatus[status.milestoneId] = status.studentRequestGrading;
    });
    setSelectedRecords(initialSelected);
    setStudentStatus(initialStudentStatus);
  }, [milestoneStatus]);

  const [selectedRecords, setSelectedRecords] = useState({});
  const [studentStatus, setStudentStatus] = useState({});
  const handleCheckboxChange = (record, isChecked) => {
    setStudentStatus((prev) => ({
      ...prev,
      [record.id]: isChecked,
    }));
  };

  const handleSubmit = async () => {
    const results = Object.entries(studentStatus).map(([id, checked]) => ({
      milestoneId: id,
      status:
        selectedRecords[id] != null
          ? selectedRecords[id]
          : checked
          ? "PENDING"
          : null,
      studentRequestGrading: checked,
    }));
    try {
      // console.log("re", results);
      await semesterStore.requestCheckMilestone(group.id, results);
      message.success("Status updated successfully");
      setVisiblePopup(false);
    } catch (e) {
      console.log(e);
    }
    console.log(results);
  };
  const column2 = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 155,
    },
    {
      title: "From",
      render: (record) => record.fromDate.format(DATE_FORMAT_SLASH),
      width: 160,
    },
    {
      title: "To/Deadline",
      render: (record) => record.toDate.format(DATE_FORMAT_SLASH),
      width: 160,
    },
    {
      title: "Student Status",
      key: "studentStatus",
      render: (record) => (
        <Checkbox
          checked={studentStatus[record.id] || false}
          onChange={(e) => handleCheckboxChange(record, e.target.checked, true)}
          disabled={
            selectedRecords[record.id]
              ? selectedRecords[record.id] != "REDO"
              : false
          }
        />
      ),
      width: 120,
    },
    {
      title: "Supervisor check done",
      key: "done",
      render: (record) => StatusTag("GROUP", selectedRecords[record.id]),
      width: 100,
    },
  ].filter(Boolean);
  const column1 = [
    {
      title: "Inter",
      dataIndex: "name",
      key: "name",
    },
  ].filter(Boolean);
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  return (
    <Modal
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      title={`Request progress checks to supervisor for ${currentMilestone?.name}`}
      cancelText="Cancel"
      width={800}
    >
      {currentMilestone?.id == 10 ? (
        <Table
          columns={column1}
          dataSource={currentMilestone?.detail}
          rowKey="id"
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                columns={column2}
                dataSource={record?.detail}
                rowKey={(record) => record.id || uuid()}
                expandable={false}
                pagination={false}
                showHeader={true}
              />
            ),
            expandIconColumnIndex: 0,
            expandedRowKeys: autoExpandAll
              ? currentMilestone?.detail.map((record) => record.id)
              : [],
            onExpand: (expanded, record) => {
              if (!autoExpandAll) {
                if (expanded) {
                  setExpandedRows([...expandedRows, record.id]);
                } else {
                  setExpandedRows(
                    expandedRows.filter((id) => id !== record.id)
                  );
                }
              }
            },
          }}
        />
      ) : (
        <Table
          columns={column2}
          dataSource={currentMilestone?.detail}
          rowKey="id"
          pagination={false}
        />
      )}
    </Modal>
  );
};
export default memo(
  withRouter(
    inject(
      "loadingAnimationStore",
      "scoreStore",
      "semesterStore"
    )(observer(MilestoneEvaluationProgressModal))
  )
);
