import { MyUploadAdapter } from "../CustomCKEditorImageUpload/MyUploadAdapter";
import { CKEDITOR_HEIGHT } from "../../ui_constants";
import React, { useEffect, useRef } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorWrapper } from "./RichEditorStyled";

const toolbar = [
  "insertTable",
  "bold",
  "italic",
  "link",
  "bulletedList",
  "numberedList",
  "blockQuote",
  "undo",
  "redo",
];

const RichEditor = ({ editorContent, placeholder, height, EDITOR_REF }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      editor.plugins.get("FileRepository").createUploadAdapter = function (
        loader
      ) {
        return new MyUploadAdapter(loader);
      };

      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );

      editor.editing.view.change((writer) => {
        writer.setStyle(
          "height",
          height ?? CKEDITOR_HEIGHT,
          editor.editing.view.document.getRoot()
        );
      });
    }
  }, [editorRef, height]);

  return (
    <EditorWrapper>
      <CKEditor
        ref={EDITOR_REF}
        editor={ClassicEditor}
        config={{
          toolbar: toolbar,
          placeholder: placeholder ?? "",
          link: {
            addTargetToExternalLinks: true,
          },
          table: {
            contentToolbar: [
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "tableProperties",
              "tableCellProperties",
            ],
          },
        }}
        data={editorContent}
      />
    </EditorWrapper>
  );
};

export default RichEditor;
