import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// Component
import PopupNotificationPage from "../../pages/PopupNotificationPage/PopupNotificationPage";
// Styled Component
import {
  ListWrapper,
  NotificationItem,
  ViewAll,
} from "../../layouts/DashboardLayout/DashboardLayoutStyled";
import { NotiWrapper } from "../MainSidebar/MainSidebarStyled";
import { Notification } from "./MainHeaderBarStyled";
// Ant design
import { Avatar, Badge, Dropdown, Menu, message, Tabs, Tooltip } from "antd";
import {
  BellFilled,
  CloseOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";
// Other
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useSSO } from "../../config";
import { blue } from "../../color";
import utils from "../../utils";
import queryString from "query-string";
import notificationStore from "../../stores/notificationStore";
import { useKeycloak } from "@react-keycloak/web";
import { subStringAvatar } from "../Common/CellText";
import moment from "moment";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
const { TabPane } = Tabs;
import { apiUrl } from "../../config";

const MainHeaderBar = (props) => {
  const {
    authenticationStore,
    accountStore,
    history,
    companyStore,
    userStore,
    notificationStore,
  } = props;

  const { unreadNotificationCount, unreadNewsCount } = notificationStore;
  const {
    currentUserAvatar,
    currentUser,
    isSupervisor,
    isStudent,
  } = authenticationStore;

  const [visibleNotification, setVisibleNotification] = useState(false);
  const [changedTabsNotification, setChangedTabsNotification] = useState({
    status: false,
    onlyNewsNotification: false,
    isOpen: false,
  });

  const disabledKeyCloak = {
    keycloak: null,
  };
  const { keycloak, initialized } = useSSO ? useKeycloak() : disabledKeyCloak;

  const [hovered, setHovered] = useState("");

  const clickLogout = useCallback(() => {
    accountStore.clearStore();
    companyStore.clearStore();
    userStore.clearStore();
    notificationStore.clearStore();
    authenticationStore.userLogout();

    if (keycloak) {
      keycloak.logout();
    }

    if (!useSSO) {
      history.replace("/");
    }
  }, []);
  useEffect(() => {
    const client = new Client({
      brokerURL: `ws://${apiUrl}/ws`,
      webSocketFactory: () => new SockJS(`${apiUrl}/ws`),
      onConnect: () => {
        client.subscribe("/topic/notification", (message) => {
          if (message.body) {
            notificationStore.getUnreadNewsCount();
            notificationStore.getUnreadNotificationCount();
          }
        });
      },
    });
    client.activate();
    return () => client.deactivate();
  }, []);
  useEffect(() => {
    if (!utils.isIOSDevice()) {
      notificationStore.getUnreadNewsCount();
      notificationStore.getUnreadNotificationCount();
    }
  }, [changedTabsNotification]);

  const menu = (
    <Menu>
      {isSupervisor && (
        <Menu.Item
          onClick={() => history.push("/supervisor/profile")}
          style={{ color: blue }}
        >
          <div className="flex items-center">
            <UserOutlined style={{ color: blue, marginRight: "5px" }} />
            My profile
          </div>
        </Menu.Item>
      )}
      {isStudent && (
        <Menu.Item
          onClick={() => history.push("/profile")}
          style={{ color: blue }}
        >
          <div className="flex items-center">
            <UserOutlined style={{ color: blue, marginRight: "5px" }} />
            My profile
          </div>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => history.push("/notification")}
        style={{ color: blue }}
      >
        <div className="flex items-center">
          <BellFilled style={{ color: blue, marginRight: "5px" }} />
          Notification
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => clickLogout()} danger>
        <div className="flex items-center">
          <LogoutOutlined style={{ marginRight: "5px" }} />
          Log out
        </div>
      </Menu.Item>
    </Menu>
  );

  const onChangeTabsNotification = (tab) => {
    setChangedTabsNotification({
      status: true,
      onlyNewsNotification: tab === "news",
      isOpen: true,
    });
  };
  const handleNotificationClick = (type) => {
    if (type === "REQUESTGROUP") {
      history.push(`/registration/myRequest`);
    }
  };

  const notiWrapper = (
    <NotiWrapper>
      <Tabs
        onChange={onChangeTabsNotification}
        defaultActiveKey="1"
        tabBarExtraContent={
          <Tooltip title={"Đóng"}>
            <span
              className={"close-notification"}
              onClick={() => setVisibleNotification(false)}
            >
              <CloseOutlined />
            </span>
          </Tooltip>
        }
      >
        <TabPane
          tab={
            <Badge
              count={
                unreadNotificationCount > 99 ? "99+" : unreadNotificationCount
              }
            >
              <span style={{ paddingRight: 13 }}>Notification</span>
            </Badge>
          }
          key="notification"
        >
          <ListWrapper>
            <ViewAll
              onClick={() => {
                setVisibleNotification(false);
                const queryStringParams = queryString.stringify({
                  unread_notification: false,
                });
                history.push(`/notification?${queryStringParams}`);
              }}
            >
              <a>View all</a>
            </ViewAll>
            <PopupNotificationPage
              changedTabsNotification={changedTabsNotification}
              onlyNewsNotification={false}
              visibleNotification={visibleNotification}
              setVisibleNotification={() => setVisibleNotification(false)}
            />
          </ListWrapper>
        </TabPane>
        <TabPane
          tab={
            <Badge count={unreadNewsCount > 99 ? "99+" : unreadNewsCount}>
              <span style={{ paddingRight: 13 }}>General Notification</span>
            </Badge>
          }
          key="news"
        >
          <ListWrapper>
            <ViewAll
              onClick={() => {
                setVisibleNotification(false);
                const queryStringParams = queryString.stringify({
                  only_news_notification: true,
                });
                history.push(`/notification?${queryStringParams}`);
              }}
            >
              <a>View all</a>
            </ViewAll>
            <PopupNotificationPage
              changedTabsNotification={changedTabsNotification}
              onlyNewsNotification={true}
              setVisibleNotification={() => setVisibleNotification(false)}
            />
          </ListWrapper>
        </TabPane>
      </Tabs>
    </NotiWrapper>
  );

  const unreadTotalCount = unreadNewsCount + unreadNotificationCount;

  return (
    <>
      {/* {windowWidth < 1300 && (
        <div>
          <MenuOutlined style={{ color: "#fff" }} />
        </div>
      )} */}
      <div className={"logo"}>
        <img
          onClick={() => history.push("/home")}
          src={`${process.env.PUBLIC_URL}/assets/photos/FPT_logo_2010.webp`}
          alt="logo"
          className="logoImg"
        />
      </div>

      {/* <Tooltip placement="top" title={"Hướng dẫn sử dụng"}>
        <a
          href=""
          target="_blank"
          className="sidebarItem avatar"
          style={{
            fontWeight: 500,
            fontSize: 14,
            textDecoration: "underline",
          }}
        >
          Guide
        </a>
      </Tooltip> */}
      <NotificationItem
        className="sidebarItem"
        showNotification={visibleNotification}
      >
        <Tooltip placement="bottom" title={"Notification"}>
          <Dropdown
            overlay={notiWrapper}
            placement="bottomRight"
            trigger={["click"]}
            visible={visibleNotification}
            onVisibleChange={(flag) => setVisibleNotification(flag)}
          >
            <div
              className={"notificationItemBox"}
              onClick={() => {
                if (!visibleNotification) {
                  setChangedTabsNotification({
                    status: true,
                    onlyNewsNotification:
                      changedTabsNotification.onlyNewsNotification,
                    isOpen: true,
                  });
                }
                setVisibleNotification(!visibleNotification);
              }}
            >
              <div style={{ position: "relative" }}>
                <BellFilled style={{ color: "#fff", fontSize: 18 }} />
                <span
                  className={
                    unreadTotalCount !== 0
                      ? "numberNotification"
                      : "noNotification"
                  }
                >
                  {unreadTotalCount > 99 ? "99+" : unreadTotalCount}
                </span>
              </div>
            </div>
          </Dropdown>
        </Tooltip>
      </NotificationItem>
      <div style={{ marginRight: 6 }}>
        <Dropdown
          overlay={menu}
          placement="topLeft"
          trigger={["click"]}
          className="sidebarItem"
        >
          <Tooltip placement="bottom" title={"Tài khoản"}>
            <div
              onClick={() => setVisibleNotification(false)}
              className="sidebarItem avatar"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  size={28}
                  src={
                    currentUserAvatar && URL.createObjectURL(currentUserAvatar)
                  }
                  style={{ backgroundColor: blue, fontSize: 12 }}
                >
                  {currentUser && subStringAvatar(currentUser.username)}
                </Avatar>
                <span
                  style={{
                    margin: "0 7px",
                    fontWeight: 500,
                  }}
                >
                  {currentUser &&
                    utils.getNameInCapitalize(currentUser.username)}
                </span>
              </div>
            </div>
          </Tooltip>
        </Dropdown>
      </div>
    </>
  );
};

MainHeaderBar.propTypes = {
  title: PropTypes.string,
};

export default memo(
  withRouter(
    inject(
      "authenticationStore",
      "accountStore",
      "userStore",
      "companyStore",
      "notificationStore",
      "loadingAnimationStore",
      "commonStore"
    )(observer(MainHeaderBar))
  )
);
