import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { oauth } from "./config";
import firebase from "firebase/app";
import "firebase/messaging";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";

ReactDOM.render(
  <GoogleOAuthProvider clientId={oauth.clientId}>
    <App />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
