import React, { memo, useEffect, useState } from "react";
import {
  Typography,
  List,
  Table,
  Modal,
  Upload,
  Button,
  Divider,
  message,
  notification,
} from "antd";
import ContentBlockWrapper from "../../components/ContentBlockWrapper";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Helmet } from "react-helmet/es/Helmet";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { apiUrl } from "../../config";
import moment from "moment";
import { DD_MM_YYYY_HH_mm } from "../../constants";

const { Title, Text, Paragraph } = Typography;
import {
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const CapstoneProjectSubmitPage = (props) => {
  const {
    history,
    loadingAnimationStore,
    groupStore,
    semesterStore,
    authenticationStore,
    match,
  } = props;
  const [members, setMembers] = useState([]);
  const [group, setGroup] = useState();
  const [submission, setSubmission] = useState();
  const [submissionType, setSubmissionType] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [refresh, setRefresh] = useState(false);
  const [submissionStatuses, setSubmissionStatuses] = useState({
    reports: false,
    slides: false,
    others: false,
  });
  const [submittedFiles, setSubmittedFiles] = useState({
    reports: [],
    slides: [],
    others: [],
  });
  useEffect(() => {
    if (authenticationStore.currentUser?.group) {
      getGroupInfo();
    }
  }, [authenticationStore.currentUser]);

  const getGroupInfo = async () => {
    loadingAnimationStore.showSpinner(true);
    try {
      const res = await groupStore.getGroupByMemberId();
      setGroup(res.data);
      setMembers(res.data.members);
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };
  useEffect(() => {
    group && getSubmissionByGroup(group.id);
  }, [group, refresh]);

  const getSubmissionByGroup = async (groupId) => {
    loadingAnimationStore.showSpinner(true);
    try {
      const res = await groupStore.getSubmissionByGroup(groupId);
      setSubmission(res.data);
      const statuses = {
        reports: res.data.files.some(
          (file) => file.submissionType === "reports"
        ),
        slides: res.data.files.some((file) => file.submissionType === "slides"),
        others: res.data.files.some((file) => file.submissionType === "others"),
      };
      const filesByType = {
        reports: res.data.files.filter(
          (file) => file.submissionType === "reports"
        ),
        slides: res.data.files.filter(
          (file) => file.submissionType === "slides"
        ),
        others: res.data.files.filter(
          (file) => file.submissionType === "others"
        ),
      };
      setSubmissionStatuses(statuses);
      setSubmittedFiles(filesByType);
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };

  const renderDirectoryItem = (type, label) => (
    <div
      style={{
        color: submissionStatuses[type] ? "green" : "blue",
        cursor: "pointer",
      }}
      onClick={() => {
        if (
          submission?.approvedBySupervisor !== "APPROVED" &&
          submission.startSubmit != null &&
          moment().isBetween(
            moment(submission.startSubmit),
            moment(submission.endSubmit)
          )
        ) {
          showModal(label, type);
        } else {
          notification.warning({
            message: "Action Not Allowed",
            description:
              "THE LECTURER HAS APPROVED OR SECRETARY HAS CONFIRMED THE PROJECT SO STUDENT CANNOT EDIT, MAYBE IT IS NOT YET THE DEADLINE FOR SUBMITTING THE PROJECT",
          });
        }
      }}
    >
      {submissionStatuses[type] ? "📁" : "📁"} {label}
    </div>
  );
  const columns = [
    {
      title: "Group",
      render: (record) => group?.groupCode,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (text) => (
        <Text style={{ color: "red" }}>
          {submission?.startSubmit
            ? `${moment(submission.startSubmit).format(
                DD_MM_YYYY_HH_mm
              )} - ${moment(submission.endSubmit).format(DD_MM_YYYY_HH_mm)}`
            : "Not set"}
        </Text>
      ),
    },
    {
      title: "Supervisor's approval",
      dataIndex: "supervisorApproval",
      key: "supervisorApproval",
      render: (record) => (
        <Text
          style={{
            color:
              submission?.approvedBySupervisor === "Approved"
                ? "black"
                : submission?.approvedBySupervisor === "Rejected"
                ? "red"
                : "orange",
          }}
        >
          {submission?.approvedBySupervisor === "APPROVED"
            ? "Confirm"
            : submission?.approvedBySupervisor === "REJECT"
            ? "Reject"
            : "Not Confirm"}
        </Text>
      ),
    },
    {
      title: "Directories",
      dataIndex: "directories",
      key: "directories",
      render: () => (
        <div>
          {renderDirectoryItem("reports", "Final Thesis + Reports")}
          {renderDirectoryItem("slides", "Slides")}
          {renderDirectoryItem("others", "Others")}
        </div>
      ),
    },
    {
      title: "Students",
      dataIndex: "students",
      key: "students",
      render: (students) => (
        <div>
          {members?.map((member, index) => (
            <div key={index}>
              <Text strong>{member?.student.user.name}</Text> <br />
              {member?.student.user.email}
            </div>
          ))}
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      deadline: "2024-08-20 08:00:00\n2024-08-21 17:00:00",
      supervisorApproval: "Not Approved",
      secretaryConfirmation: "Not confirmed",
      supervisor: "huectm@fpt.edu.vn",
    },
  ];
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleOk = async () => {
    const existingFileNames = submission
      ? submission.files.map((file) => file.filename)
      : [];
    const invalidFiles = fileList.filter(
      (file) =>
        /\s/.test(file.name) ||
        /[^\w\.-]/.test(file.name) ||
        existingFileNames.includes(group?.groupCode + "_" + file.name)
    );

    if (invalidFiles.length > 0) {
      Modal.error({
        title: "Invalid file names",
        content: (
          <div>
            The following files have invalid names and cannot be uploaded:
            <ul>
              {invalidFiles.map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
            File names cannot contain special characters, spaces, or duplicate
            existing file names.
          </div>
        ),
      });
    } else {
      loadingAnimationStore.showSpinner(true);
      try {
        if (fileList.length == 0) {
          message.error("Please enter file to upload!");
        } else {
          const formData = new FormData();
          fileList.forEach((file) => {
            formData.append("files", file.originFileObj);
          });
          formData.append("type", submissionType);
          const res = await groupStore.uploadFiles(group?.id, formData);
          setRefresh(true);
          setFileList([]);
          if (res.status == 200) {
            message.success("Upload files successfully!");
          }
        }
      } catch (err) {
        console.log(err);
        loadingAnimationStore.showSpinner(false);
      } finally {
        loadingAnimationStore.showSpinner(false);
      }
    }
  };

  const showModal = (title, type) => {
    setSubmissionType(type);
    setModalTitle(title);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setFileList([]);
    setIsModalVisible(false);
  };
  const handleDownload = async (file) => {
    try {
      loadingAnimationStore.showSpinner(true);
      const downloadUrl = `${apiUrl}/api/v1/group/download/file/${file.id}`;
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };
  const handleDownloadAll = async () => {
    try {
      loadingAnimationStore.showSpinner(true);
      const downloadUrl = `${apiUrl}/api/v1/group/download/all/${group?.id}/${submissionType}`;
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${group?.groupCode} - ${submissionType}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };
  const handleDelete = async (file) => {
    try {
      loadingAnimationStore.showSpinner(true);
      const res = await groupStore.deleteFile(file?.id);
      if (res.status == 200) {
        setRefresh(true);
        message.success("Delete file successfully!");
      }
    } catch (err) {
      message.error("Delete file error!");
      loadingAnimationStore.showSpinner(false);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };
  const handleUpload = ({ fileList }) => {
    const uniqueFileList = fileList.reduce((acc, current) => {
      const duplicate = acc.find((file) => file.name === current.name);
      if (!duplicate) {
        acc.push(current);
      } else {
        message.warning(`File "${current.name}" is exist.`);
      }
      return acc;
    }, []);

    setFileList(uniqueFileList);
  };

  const renderFileList = (files) => (
    <div>
      {files.length > 0 ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Submitted file:</span>
            <Button
              icon={<DownloadOutlined />}
              onClick={(e) => {
                e.preventDefault();
                handleDownloadAll();
              }}
            >
              Download All
            </Button>
          </div>
          <Divider />
          {files.map((file) => (
            <div
              key={file.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{file.filename}</div>
              <div>
                <Button
                  icon={<DownloadOutlined />}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload(file);
                  }}
                />
                <Button
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(file);
                  }}
                  style={{ marginLeft: 8 }}
                />
              </div>
            </div>
          ))}
          <Divider />
        </div>
      ) : (
        <div>
          <div style={{ textAlign: "center" }}>No files submitted yet.</div>
          <Divider />
        </div>
      )}
    </div>
  );

  return (
    <DashboardLayout>
      <Helmet>
        <title>Capstone project submit</title>
      </Helmet>
      <ContentBlockWrapper>
        <div style={{ padding: "20px" }}>
          <Title level={3}>Capstone project submit</Title>
          <Paragraph>
            <Text strong>Permission:</Text> Student
          </Paragraph>
          <Paragraph>
            <Text strong>Name:</Text> {group?.name} ({group?.vietnameseTitle})
          </Paragraph>
          <Paragraph>
            <Text strong>Warning:</Text>
          </Paragraph>
          <List
            dataSource={[
              "The maximum upload size for a single file is 2GB .",
              "Students, please appoint a representative to submit the project .",
              "File names cannot contain special characters and spaces .",
              <div>
                <Text strong>Examples (Ví dụ):</Text>
                <List
                  dataSource={[
                    'Valid name : "G1_GRM491_G1_Present_Order.pdf"',
                    'Invalid name : "G1_GRM491_G1 Present order.pdf" (No spaces allowed).',
                    'Invalid name : "G1_GRM491_G1 Trình tự hiện tại.pdf" (Vietnamese and spaces are not accepted.).',
                  ]}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </div>,
              "When the project supervisor has approved or the project deadline has expired, the student cannot modify it anymore.",
              "Please download the file to check whether the upload process has been successfully completed or not.",
              <div>
                <Text strong>Folder Structures:</Text>

                <List
                  dataSource={[
                    "Student must upload the thesis file to the 'Final Thesis + Reports' directory .",
                    "Student must upload presentation files to the 'Slides' directory .",
                    "Student must upload (database, code, etc.) files to the 'Others' directory .",
                  ]}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </div>,
              "Reports and Final Thesis Directories accepts files with 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'gif', 'pptx', 'txt', 'odt', 'ods', 'ppt' extensions. Please move the 'Others' Directories to upload other types of document: Code, Video, Audio, etc .",
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
        <div style={{ padding: "20px" }}>
          <Typography.Title level={4}>Secretary note:</Typography.Title>
          {group && (
            <Table columns={columns} dataSource={data} pagination={false} />
          )}
        </div>
        <Modal
          title={`Upload files to ${modalTitle}`}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Submit Files"
        >
          {renderFileList(submittedFiles[submissionType] || [])}
          <div className="flex justify-center">
            <Upload
              multiple={true}
              fileList={fileList}
              onChange={handleUpload}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </div>
        </Modal>
      </ContentBlockWrapper>
    </DashboardLayout>
  );
};
export default memo(
  withRouter(
    inject(
      "authenticationStore",
      "loadingAnimationStore",
      "semesterStore",
      "groupStore",
      "authenticationStore"
    )(observer(CapstoneProjectSubmitPage))
  )
);
