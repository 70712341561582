import axios from "axios";
import { apiUrl } from "../config";
import authenticationStore from "../stores/authenticationStore";

export const CommitteeRequest = {
  getCommitteeList: (pageSize, pageNumber, semesterId) =>
    axios({
      method: "get",
      url: `${apiUrl}/api/v1/staff/committees`,
      headers: {
        Authorization: `Bearer ${JSON.parse(authenticationStore.appToken)}`,
        "Content-Type": "application/json",
      },
      params: {
        page: pageNumber,
        size: pageSize,
        semesterId: semesterId,
      },
    }),
  getCommitteeAssignmentList: (pageSize, pageNumber, semesterId) =>
    axios({
      method: "get",
      url: `${apiUrl}/api/v1/staff/committee-assignment`,
      headers: {
        Authorization: `Bearer ${JSON.parse(authenticationStore.appToken)}`,
        "Content-Type": "application/json",
      },
      params: {
        page: pageNumber,
        size: pageSize,
        semesterId: semesterId,
      },
    }),
};
