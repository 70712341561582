import React, { memo, useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter, useLocation } from "react-router-dom";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Avatar,
  Space,
  Radio,
  Card,
  Typography,
} from "antd";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Helmet } from "react-helmet/es/Helmet";
import { ContentInformation } from "../ProfilePage/ProfilePageStyled";
import PageTitle from "../../components/PageTitle";

const ProfileSupervisorPage = (props) => {
  const {
    history,
    supervisorStore,
    authenticationStore,
    loadingAnimationStore,
  } = props;
  const { currentUser } = authenticationStore;
  const { state } = useLocation();
  const { Title } = Typography;
  const [isDisable, setIsDisable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (authenticationStore.currentUser) {
      getSupervisorProfile(currentUser?.id);
    }
  }, [authenticationStore.currentUser]);
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [supervisor, setSupervisor] = useState();
  const handleEditProfile = useCallback(() => {
    setIsEditing((prevState) => !prevState);
  }, []);
  const getSupervisorProfile = async (userId) => {
    try {
      loadingAnimationStore.showSpinner(true);
      const response = await supervisorStore.getSupervisorProfileById(userId);
      console.log("infor", response);
      if (response.status === 200) {
        setSupervisor(response.data);
        form.setFieldsValue({
          userId: currentUser?.id,
          fullName: response.data.fullName,
          fptEmail: response.data.fptEmail,
          persionalEmail: response.data.persionalEmail,
          phone: response.data.phone,
          selfDescription: response.data.selfDescription,
        });
      }
    } catch (err) {
      loadingAnimationStore.showSpinner(false);
      message.error(err.en);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };
  const handleSubmit = async (values) => {
    try {
      console.log("value", values);
      loadingAnimationStore.showSpinner(true);
      const response = await supervisorStore.updateSupervisor(
        values.userId,
        values.fullName,
        values.fptEmail,
        values.persionalEmail,
        values.phone,
        values.selfDescription
      );
      if (response.status === 200) {
        getSupervisorProfile(currentUser?.id);
        handleEditProfile();
        message.success("Update profile successfully");
      }
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
      message.error(err.en || "Login failed response status!");
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };
  const handleDisable = useCallback(() => {
    setIsDisable((prevState) => !prevState);
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <title>Detail Profile of Supervisor</title>
      </Helmet>
      <PageTitle
        location={location}
        title={"Detail Supervisors"}
        showTitle={true}
      ></PageTitle>
      <Form
        form={form}
        onFinish={handleSubmit}
        labelAlign="left"
        layout="horizontal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 20,
        }}
        className="flex items-start gap-30"
      >
        <ContentInformation className="flex flex-col items-center justify-center w-30p p-8 rounded-md">
          <Space direction="vertical" size={16} className="">
            <Space wrap size={16}>
              <img
                src="https://tse2.mm.bing.net/th?id=OIP.gHmt_-48RFhIluX7nT5zBwHaHa&pid=Api&P=0&h=180"
                alt="Avatar"
                className="rounded-circle"
                width="171"
              />
            </Space>
          </Space>
          <Title level={4} style={{ display: !isDisable ? "block" : "none" }}>
            {supervisor?.fullName}
          </Title>
          <div
            className="w-full"
            style={{ display: isDisable ? "block" : "none" }}
          >
            <Form.Item label="Full Name" name="fullName">
              <Input style={{ maxWidth: "100%" }} />
            </Form.Item>
          </div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={handleDisable}
            style={{ display: !isDisable ? "flex" : "none" }}
            className="flex items-center justify-center mt-1"
          >
            Edit My Profile
          </Button>
        </ContentInformation>
        <ContentInformation className="w-70p p-8 rounded-md">
          <Form.Item name="userId" className="hidden">
            <Input
              style={{
                maxWidth: "100%",
                border: isDisable ? "" : "none",
                pointerEvents: isDisable ? "all" : "none",
              }}
            />
          </Form.Item>
          <Form.Item label="FPT Email" name="fptEmail">
            <Input
              style={{
                maxWidth: "100%",
                border: isDisable ? "" : "none",
                pointerEvents: isDisable ? "all" : "none",
              }}
            />
          </Form.Item>
          <Form.Item label="Personal Email" name="persionalEmail">
            <Input
              style={{
                maxWidth: "100%",
                border: isDisable ? "" : "none",
                pointerEvents: isDisable ? "all" : "none",
              }}
            />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Input
              style={{
                maxWidth: "100%",
                border: isDisable ? "" : "none",
                pointerEvents: isDisable ? "all" : "none",
              }}
            />
          </Form.Item>
          <Form.Item label="Self Description" name="selfDescription">
            <TextArea
              rows={5}
              style={{
                maxWidth: "100%",
                resize: "none",
                border: isDisable ? "" : "none",
                pointerEvents: isDisable ? "all" : "none",
              }}
              // value={supervisor?.selfDescription}
            />
          </Form.Item>
          <div
            className="flex items-center justify-center gap-5"
            style={{ display: isDisable ? "flex" : "none" }}
          >
            <Button onClick={handleDisable} type="danger">
              Cancel
            </Button>
            <Button onClick={handleDisable} type="primary" htmlType={"submit"}>
              Submit
            </Button>
          </div>
        </ContentInformation>
      </Form>
    </DashboardLayout>
  );
};
export default memo(
  withRouter(
    inject(
      "supervisorStore",
      "authenticationStore",
      "loadingAnimationStore"
    )(observer(ProfileSupervisorPage))
  )
);
