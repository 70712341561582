import HomePage from "./pages/HomePage/HomePage";
import TeamPage from "./pages/Registration/TeamPage/TeamPage";
import ListRequestPage from "./pages/Registration/ListRequestPage/ListRequestPage";
import CreateIdeaPage from "./pages/Registration/CreateIdeaPage/CreateIdeaPage";
import ListSupervisorsPage from "./pages/Registration/ListSupervisorsPage/ListSupervisorsPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import ProfileSupervisorPage from "./pages/ProfileSupervisorPage";
import CreateNotePage from "./pages/Meeting/Note/CreateNotePage";
import DetailProfileSupervisorPage from "./pages/DetailProfileSupervisorPage";
import NotePage from "./pages/Meeting/Note/NotePage";
import SchedulePage from "./pages/Guidance/SchedulePage";
import ManageTaskPage from "./pages/Guidance/ManageTaskPage";
import NotificationPage from "./pages/NotificationPage/NotificationPage";
import ProgressPage from "./pages/ProgressPage";
import CapstoneProjectSubmitPage from "./pages/CapstoneProjectSubmitPage/CapstoneProjectSubmitPage";

export const normalRoutes = [
  {
    path: "/login",
    component: HomePage,
    name: "Login",
  },
];

export const routes = [
  {
    path: "/",
    component: HomePage,
    name: "Home",
  },
  {
    path: "/home",
    component: HomePage,
    name: "DashboardPage",
  },
  {
    path: "/registration/team",
    component: TeamPage,
    name: "TeamPage",
  },
  {
    path: "/registration/myRequest",
    component: ListRequestPage,
    name: "ListRequestPage",
  },
  {
    path: "/registration/listSupervisors",
    component: ListSupervisorsPage,
    name: "ListSupervisorsPage",
  },
  {
    path: "/registration/createIdea",
    component: CreateIdeaPage,
    name: "CreateIdeaPage",
  },
  {
    path: "/profile/:id",
    component: ProfilePage,
    name: "ProfilePage",
  },
  {
    path: "/profile",
    component: ProfilePage,
    name: "ProfilePage",
  },
  {
    path: "/registration/supervisor/detail",
    component: DetailProfileSupervisorPage,
    name: "DetailProfileSupervisorPage",
  },

  //student-phase-2

  {
    path: "/guidance/meeting/note/create",
    component: CreateNotePage,
    name: "CreateNotePage",
  },
  {
    path: "/guidance/meeting/notes/:meetingId",
    component: NotePage,
    name: "NotePage",
  },
  {
    path: "/guidance/schedule",
    component: SchedulePage,
    name: "SchedulePage",
  },
  {
    path: "/notification",
    component: NotificationPage,
    name: "NotificationPage",
  },
  {
    path: "/student/progress",
    component: ProgressPage,
    name: "ProgressPage",
  },
  {
    path: "/supervisor/profile",
    component: ProfileSupervisorPage,
    name: "Profile Supervisor",
  },
  {
    path: "/cp/submit",
    component: CapstoneProjectSubmitPage,
    name: "CapstoneProjectSubmitPage",
  },
];
