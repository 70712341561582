import React, { memo, useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import {
  Button,
  Table,
  Typography,
  Select,
  Progress,
  Drawer,
  Collapse,
  Row,
  Col,
  Modal,
  Input,
  Form,
  message,
} from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import uuid from "uuid";
import DashboardLayout from "../../layouts/DashboardLayout";
import ContentBlockWrapper from "../../components/ContentBlockWrapper";
import { NoMarginBottom } from "./ProgressPageStyled";
import PageTitle from "../../components/PageTitle";
import { Helmet } from "react-helmet/es/Helmet";
import ViewProgress from "../../components/ViewProgress/ViewProgress";
import moment from "moment";
import utils from "../../utils";
import { DATE_FORMAT_SLASH } from "../../constants";
const { Panel } = Collapse;
const { Title } = Typography;
import MemberItem from "../Registration/TeamPage/MemberItem";
import {
  GroupBtn,
  ContentInformation,
  FontSize14px,
} from "../ProfilePage/ProfilePageStyled";
import MilestonesEvaluationProgressModal from "./MilestonesEvaluationProgressModal";

const ProgressPage = (props) => {
  const {
    history,
    loadingAnimationStore,
    groupStore,
    semesterStore,
    authenticationStore,
    match,
  } = props;
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [members, setMembers] = useState([]);
  const [group, setGroup] = useState();
  const [refresh, setRefresh] = useState(false);
  const [report, setReport] = useState();
  const [currentMilestone, setCurrentMilestone] = useState("");
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [milestoneStatus, setMilestoneStatus] = useState();
  const [
    visiblePopupEvaluateProgress,
    setVisiblePopupEvaluateProgress,
  ] = useState(false);

  const [selectedSemesterId, setSelectedSemesterId] = useState(null);
  useEffect(() => {
    getSemester();
  }, []);

  const [form] = Form.useForm();

  useEffect(() => {
    if (authenticationStore.currentUser?.group) {
      getGroupInfo();
    }
  }, [refresh, authenticationStore.currentUser]);

  const getGroupInfo = async () => {
    loadingAnimationStore.showSpinner(true);
    try {
      const res = await groupStore.getGroupByMemberId();
      setGroup(res.data);
      setMembers(res.data.members);
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };

  useEffect(() => {
    selectedSemesterId && getReports(selectedSemesterId);
  }, [selectedSemesterId]);
  useEffect(() => {
    if (group && currentStepIndex === 0) {
      form.setFieldsValue({
        gitID: group?.gitId,
      });
    }
  }, [currentStepIndex, group]);
  const getSemester = async () => {
    try {
      const res = await semesterStore.getSemesters();
      setSemesterCurrent(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getReports = async (selectedSemesterId) => {
    try {
      const res = await semesterStore.getReports(selectedSemesterId);
      setReport(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  const handleOpenPopupEvaluateProgress = (milestone) => {
    setCurrentMilestone(milestone);
    setVisiblePopupEvaluateProgress(true);
  };
  const handleCancelEvaluateProgress = () => {
    setVisiblePopupEvaluateProgress(false);
  };
  const setSemesterCurrent = (semesters) => {
    if (semesters.length > 0) {
      const currentDate = moment();
      const closestSemester = semesters.reduce((prev, curr) => {
        const startDate = moment(curr.beginAt);
        const endDate = moment(curr.endAt);
        if (currentDate.isBetween(startDate, endDate, null, "[]")) {
          return curr;
        } else {
          if (prev !== null) {
            const prevDiff = Math.abs(
              moment(prev.beginAt).diff(currentDate, "days")
            );
            const currDiff = Math.abs(startDate.diff(currentDate, "days"));
            return currDiff < prevDiff ? curr : prev;
          } else {
            return curr;
          }
        }
      }, null);
      if (closestSemester) {
        setData(utils.transformData(closestSemester.milestones));
        setSelectedSemesterId(closestSemester.id);
      }
    }
  };
  const currentDate = moment();

  const isCurrentMilestone = (record) => {
    return currentDate.isBetween(record.fromDate, record.toDate, "day", "[]");
  };
  const [progressStatus, setProgressStatus] = useState({});

  useEffect(() => {
    const fetchProgressStatus = async () => {
      const progressData = await Promise.all(
        data[4]?.detail.map(async (record) => ({
          id: record.id,
          progress: await getProgressStatus(record),
        }))
      );
      setProgressStatus(
        progressData.reduce((acc, curr) => {
          acc[curr.id] = curr.progress;
          return acc;
        }, {})
      );
    };
    data?.length > 0 && fetchProgressStatus();
  }, [data, group, visiblePopupEvaluateProgress]);
  const columnMilestoneGuidance = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 50,
    },
    {
      title: "Milestone",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "From",
      render: (record) => record.fromDate.format(DATE_FORMAT_SLASH),
      width: 150,
    },
    {
      title: "To/Deadline",
      render: (record) => record.toDate.format(DATE_FORMAT_SLASH),
      width: 150,
    },
    {
      title: "Status",
      width: 250,
      render: (record) => (
        <div className="flex">
          <Progress percent={progressStatus[record.id] || 0} />
        </div>
      ),
    },
    {
      title: "Update progress",
      render: (record) => (
        <div className="flex">
          <Button
            type="primary"
            onClick={() => handleOpenPopupEvaluateProgress(record)}
          >
            Check
          </Button>
        </div>
      ),
    },
  ];
  async function getProgressStatus(record) {
    if (group) {
      try {
        const response = await semesterStore.getProcessOfMilestone(
          group?.id,
          record?.id
        );
        if (response.status == 200) {
          setMilestoneStatus(response.data);

          const { data } = response;
          // console.log("data", data);

          let milestoneDetails = record?.detail || [];
          if (record?.name === "Implementation") {
            milestoneDetails = milestoneDetails.reduce(
              (acc, milestone) => acc.concat(milestone.detail || []),
              []
            );
          }
          const completedMilestones = milestoneDetails.filter(
            (milestone) =>
              data.find((m) => m.milestoneId === milestone.id)?.status ===
              "DONE"
          );
          const totalMilestones = milestoneDetails.length;
          const progressStatus =
            (completedMilestones.length / totalMilestones) * 100;

          return progressStatus;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    }
  }
  const getMilestoneStatus = (recordId) => {
    const milestone = milestoneStatus?.find(
      (item) => item.milestoneId === recordId
    );
    return milestone ? milestone.status : false;
  };

  const columnMilestone2 = [
    {
      title: "#",
      width: 45,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 180,
    },
    {
      title: "From",
      render: (record) => record.fromDate.format(DATE_FORMAT_SLASH),
      width: 140,
    },
    {
      title: "To/Deadline",
      render: (record) => record.toDate.format(DATE_FORMAT_SLASH),
      width: 160,
    },
    {
      title: "submit",
      render: (record) => <div className="flex"></div>,
    },
  ];
  const handleStepChange = (index) => {
    setCurrentStepIndex(index);
  };
  const handleSubmit = async (values) => {
    try {
      loadingAnimationStore.showSpinner(true);
      const response = await groupStore.addGit(values.gitID, group?.id);
      if (response.status === 200) {
        message.success("Add git successfully");
      }
    } catch (err) {
      console.log(err);
      loadingAnimationStore.showSpinner(false);
      message.error(err.en || "GitID is not exist!");
    } finally {
      loadingAnimationStore.showSpinner(false);
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Manager Group</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={"Manager group"}
        hiddenGoBack
      ></PageTitle>
      <div className="flex items-start gap-5">
        <div className="lg:w-8/12 max-lg:w-full">
          <ContentInformation className="p-4 mb-5 max-lg:sticky">
            {selectedSemesterId && (
              <ViewProgress
                id={selectedSemesterId}
                onStepChange={handleStepChange}
              />
            )}
          </ContentInformation>
          <ContentInformation>
            <FontSize14px>
              {report && (
                <Table
                  columns={columnMilestoneGuidance}
                  dataSource={data[4]?.detail}
                  rowKey={(record) => record.id || uuid()}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (record) => (
                      <>
                        {record?.detail[0]?.name == null ? (
                          <Table
                            columns={columnMilestone2}
                            dataSource={record?.detail}
                            rowKey={(record) => record.id || uuid()}
                            expandable={false}
                            pagination={false}
                            showHeader={false}
                            rowClassName={(record) =>
                              isCurrentMilestone(record) ? "highlight" : ""
                            }
                          />
                        ) : (
                          <Collapse accordion>
                            {record?.detail.map((item) => (
                              <Panel
                                header={
                                  <Row
                                    style={{
                                      width: "100%",
                                      paddingLeft: "55px",
                                    }}
                                  >
                                    <Col span={4}>{item.name}</Col>
                                    <Col span={4}>
                                      {item?.fromDate?.format(
                                        DATE_FORMAT_SLASH
                                      )}
                                    </Col>
                                    <Col span={4}>
                                      {item?.toDate?.format(DATE_FORMAT_SLASH)}
                                    </Col>
                                    <Col span={8}>{item.time}</Col>
                                    <Col span={4}></Col>
                                  </Row>
                                }
                                key={item.key}
                              >
                                {item.detail.map((detail, index) => (
                                  <Row
                                    key={index}
                                    style={{ paddingLeft: "80px" }}
                                  >
                                    <Col xs={4}>{detail.product}</Col>
                                    <Col xs={4}>
                                      {detail?.fromDate?.format(
                                        DATE_FORMAT_SLASH
                                      )}
                                    </Col>
                                    <Col xs={4}>
                                      {detail?.toDate?.format(
                                        DATE_FORMAT_SLASH
                                      )}
                                    </Col>
                                    <Col xs={8}>{detail.time}</Col>
                                    <Col xs={4}></Col>
                                  </Row>
                                ))}
                              </Panel>
                            ))}
                          </Collapse>
                        )}
                      </>
                    ),
                    rowExpandable: (record) => record.detail?.length > 0,
                    expandIconColumnIndex: 0,
                    rowClassName: (record) =>
                      isCurrentMilestone(record) ? "highlight" : "",
                  }}
                  rowClassName={(record) =>
                    isCurrentMilestone(record) ? "highlight" : ""
                  }
                />
              )}
            </FontSize14px>
          </ContentInformation>
        </div>
        <div className="w-4/12">
          {currentStepIndex === 0 && (
            <ContentInformation className="w-full p-8 mb-5">
              <Form
                form={form}
                onFinish={handleSubmit}
                labelAlign="left"
                layout="horizontal"
                className="flex items-center gap-2 w-full"
              >
                <NoMarginBottom>
                  <Title level={5} style={{ whiteSpace: "nowrap" }}>
                    Git ID
                  </Title>
                </NoMarginBottom>
                <Form.Item
                  name="gitID"
                  className="w-full"
                  style={{ marginBottom: "0" }}
                >
                  <Input
                    style={{
                      maxWidth: "100%",
                    }}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  className="flex items-center justify-center"
                >
                  Submit
                </Button>
              </Form>
            </ContentInformation>
          )}
          <ContentInformation className="w-full p-8">
            <Title className="text-center" level={4}>
              GROUP {group?.name}
            </Title>
            <FontSize14px>
              <div className="mb-2">English name: {group?.name}</div>
              <div className="mb-2">
                Vietnamese name: {group?.vietnameseTitle}
              </div>
              <div className="mb-2">
                Created at: {moment(group?.createdAt).format(DATE_FORMAT_SLASH)}
              </div>
              <div className="mb-2">Abbreviations: {group?.abbreviations}</div>
              <div className="mb-2">
                Profession: Information Technology A (K15 trở đi)
              </div>
              <div className="mb-2">Specialty: Lập trình .NET</div>
              <div>Description: </div>
              <div className="mb-2">{group?.description}</div>
              <div className="mb-2">Keywords: {group?.keywords}</div>
              <div>Members:</div>
              <div className="members">
                {members.map((member, index) => (
                  <div>{member?.student.user.name}</div>
                ))}
              </div>
              <div className="mb-2"></div>
              <div className="flex items-center justify-center gap-5">
                <Button
                  className="flex items-center justify-center"
                  type="primary"
                >
                  <Link to={"/guidance/schedule"}>View Schedule</Link>
                </Button>
                <Button
                  className="flex items-center justify-center"
                  type="primary"
                >
                  <Link to={"/registration/team"}> View Group Detail</Link>
                </Button>
              </div>
            </FontSize14px>
          </ContentInformation>
        </div>
      </div>
      {report && (
        <MilestonesEvaluationProgressModal
          visible={visiblePopupEvaluateProgress}
          setVisiblePopup={setVisiblePopupEvaluateProgress}
          currentMilestone={currentMilestone}
          report={report}
          group={group}
          handleCancel={handleCancelEvaluateProgress}
          milestoneStatus={milestoneStatus}
        />
      )}
    </DashboardLayout>
  );
};
export default memo(
  withRouter(
    inject(
      "authenticationStore",
      "loadingAnimationStore",
      "semesterStore",
      "groupStore",
      "authenticationStore"
    )(observer(ProgressPage))
  )
);
