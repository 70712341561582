import axios from "axios";
import qs from "querystring";
import { apiUrl, oauth } from "../config";
import authenticationStore from "../stores/authenticationStore";

export const AuthenticationRequest = {
  userLogin: (identifier, password) =>
    axios({
      method: "post",
      url: `${apiUrl}/api/v1/auth/token`,
      data: {
        username: identifier,
        password: password,
      },
    }),
  userLoginWithGoogle: (token, campus) =>
    axios({
      method: "post",
      url: `${apiUrl}/api/v1/auth/s/check-user`,
      data: {
        token: token,
        campus: campus,
      },
    }),

  checkCurrentUser: () =>
    axios({
      method: "get",
      url: `${apiUrl}/api/v1/users/s/current-user`,
      headers: {
        Authorization: `Bearer ${JSON.parse(authenticationStore.appToken)}`,
        "Content-Type": "application/json",
      },
    }),

  updateCurrentUserAvatar: (formData) =>
    axios({
      method: "patch",
      url: `${apiUrl}/api/v1/users/current-user/image`,
      headers: {
        Authorization: `Bearer ${JSON.parse(authenticationStore.appToken)}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }),

  getCurrentUserAvatar: () =>
    axios({
      method: "get",
      url: `${apiUrl}/api/v1/users/current-user/image`,
      headers: {
        Authorization: `Bearer ${JSON.parse(authenticationStore.appToken)}`,
        "Content-Type": "image/jpeg",
      },
      responseType: "blob",
    }),
};
